import { createStitches } from "@stitches/react"
import type { PropertyValue } from "@stitches/react"

export const { styled, getCssText, theme, globalCss } = createStitches({
    theme: {
        colors: {
            proofLightYellow: "#FFEE9C",
            proofYellow: "#FFD120",
            proofPurple: "#180B5A",
            proofPink: "#FFA0BA",
            proofRed: "#FF3A4C",
            proofBlue: "#1E78FF",
            proofGrey: "#EFEFEF",
            G500: "#00664C",
            G400: "#008765",
            G300: "#36B37E",
            G200: "#36B389",
            G100: "#79F2CA",
            G75: "#ABF5D6",
            G50: "#E3FCEF",
            B500: "#0741A6",
            B400: "#004ECC",
            B300: "#297AFF",
            B200: "#2684FF",
            B100: "#4C91FF",
            B75: "#B3D0FF",
            B50: "#DEEBFF",
            R500: "#BF2600",
            R400: "#DE350B",
            R300: "#FF5630",
            R200: "#FF7452",
            R100: "#FF8F73",
            R75: "#FFBDAD",
            R50: "#FFEBE6",
            Y500: "#FF8B00",
            Y400: "#FF991F",
            Y300: "#FFAB00",
            Y200: "#FFC400",
            Y100: "#FFE380",
            Y75: "#FFF0B3",
            Y50: "#FFFAE6",
            N1000: "#000000",
            N900: "#091E42",
            N800: "#172B4D",
            N700: "#253858",
            N600: "#344563",
            N500: "#42526E",
            N400: "#505F79",
            N300: "#5E6C84",
            N200: "#6B778C",
            N100: "#7A869A",
            N90: "#8993A4",
            N80: "#97A0AF",
            N70: "#A5ADBA",
            N60: "#B3BAC5",
            N50: "#C1C7D0",
            N40: "#DFE1E6",
            N30: "#EBECF0",
            N20: "#F4F5F7",
            N10: "#FAFBFC",
            N0: "#FFFFFF"
        },
        fonts: {
            heading: "Source Sans Pro, Montserrat, 'Helvetica Neue', Helvetica, Arial, sans-serif",
            body: "Source Sans Pro, Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif"
        },
        fontWeights: {
            light: 300,
            regular: 400,
            medium: 500,
            semiBold: 600,
            bold: 700,
            extraBold: 800,
            black: 900
        },
        // Choose logic pixel values according to the design.
        fontSizes: {
            h1: "58px",
            h2: "58px",
            h3: "32px",
            h4: "22px",
            h5: "16px",
            h1Mobile: "42px",
            h2Mobile: "40px",
            h3Mobile: "30px",
            h4Mobile: "20px",
            h5Mobile: "14px",
            desktopBody: "16px",
            mobileBody: "16px"
        }
    },
    media: {
        xsDown: "(max-width: 413px)",
        smDown: "(max-width: 640px)",
        mdDown: "(max-width: 768px)",
        lgDown: "(max-width: 1024px)",
        xlDown: "(max-width: 1280px)",
        xxlDown: "(max-width: 1536px)",
        smUp: "(min-width: 640px)",
        mdUp: "(min-width: 768px)",
        lgUp: "(min-width: 1024px)",
        xlUp: "(min-width: 1280px)",
        xxlUp: "(min-width: 1536px)"
    },
    utils: {
        m: (value: PropertyValue<"margin">) => ({
            margin: value
        }),
        mt: (value: PropertyValue<"margin">) => ({
            marginTop: value
        }),
        mr: (value: PropertyValue<"margin">) => ({
            marginRight: value
        }),
        mb: (value: PropertyValue<"margin">) => ({
            marginBottom: value
        }),
        ml: (value: PropertyValue<"margin">) => ({
            marginLeft: value
        }),
        mx: (value: PropertyValue<"margin">) => ({
            marginLeft: value,
            marginRight: value
        }),
        my: (value: PropertyValue<"margin">) => ({
            marginTop: value,
            marginBottom: value
        }),
        p: (value: PropertyValue<"padding">) => ({
            padding: value
        }),
        pt: (value: PropertyValue<"padding">) => ({
            paddingTop: value
        }),
        pr: (value: PropertyValue<"padding">) => ({
            paddingRight: value
        }),
        pb: (value: PropertyValue<"padding">) => ({
            paddingBottom: value
        }),
        pl: (value: PropertyValue<"padding">) => ({
            paddingLeft: value
        }),
        px: (value: PropertyValue<"padding">) => ({
            paddingLeft: value,
            paddingRight: value
        }),
        py: (value: PropertyValue<"padding">) => ({
            paddingTop: value,
            paddingBottom: value
        }),
        // Always use this when setting a `fontSize`, except if you use a theme defined fontSize.
        fontSizePxToRem: (value: number) => ({
            fontSize: `${value / 16}rem`
        })
    }
})
